<template>
  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#F94840">
    <van-swipe-item v-for="(item, index) in list" :key="index">
      <img :src="item.detailsImg" alt="" @click="goto(item.jumpUrl)" />
    </van-swipe-item>
  </van-swipe>
</template>

<script>
  export default {
    props: {
      list: Array
    },
    methods: {
      goto(url) {
        window.location.href=url;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .my-swipe {
    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
