<template>
  <div>
    <div class="content">
      <swiper :list="swiperList"></swiper>
      <!--<van-notice-bar left-icon="volume-o" :scrollable="false">-->
      <!--<van-swipe-->
      <!--vertical-->
      <!--class="notice-swipe"-->
      <!--:autoplay="6000"-->
      <!--:show-indicators="false"-->
      <!--&gt;-->
      <!--<van-swipe-item>明月直入，无心可猜。</van-swipe-item>-->
      <!--<van-swipe-item>仙人抚我顶，结发受长生。</van-swipe-item>-->
      <!--<van-swipe-item>今人不见古时月，今月曾经照古人。</van-swipe-item>-->
      <!--</van-swipe>-->
      <!--</van-notice-bar>-->

      <!--<div class="category-list">-->
      <!--<router-link to="./category?activeIndex=0" replace>-->
      <!--<img src="@/assets/icons/nianji.png"/>-->
      <!--<span>{{category1.name}}</span>-->
      <!--</router-link>-->
      <!--<router-link to="./category?activeIndex=1" replace>-->
      <!--<img src="@/assets/icons/tigao.png"/>-->
      <!--<span>{{category2.name}}</span>-->
      <!--</router-link>-->
      <!--<router-link to="./category?activeIndex=2" replace>-->
      <!--<img src="@/assets/icons/jingdian.png"/>-->
      <!--<span>{{category3.name}}</span>-->
      <!--</router-link>-->
      <!--<router-link to="./category?type=3" replace>-->
      <!--<img src="@/assets/icons/huiyuan.png"/>-->
      <!--<span>{{category4.name}}</span>-->
      <!--</router-link>-->
      <!--<router-link to="./category?activeIndex=4" replace>-->
      <!--<img src="@/assets/icons/tigao.png"/>-->
      <!--<span>{{category5.name}}</span>-->
      <!--</router-link>-->
      <!--</div>-->

      <div class="colgroup">
        <div class="column member">
          <div class="title">会员专区</div>
          <div class="box">
            <div class="cover" >
              <a href="https://shop.hanyuanyuwen.com/courseDetail?id=19">
                <img src="@/assets/images/fde2b6cd54fec1bd010ffd31dea015b.jpg">
              </a>
            </div>
            <!--<div class="cover">-->
              <!--<img src="@/assets/images/zhongshen.jpg">-->
            <!--</div>-->
          </div>
        </div>
        <div class="column grade">
          <div class="title">部编同步课程
            <router-link :to="{path:'/courseDetail',query:{id:info1.courseShopId}}" replace class="more">
              <van-icon name="arrow" size="16px" color="#fff"/>
            </router-link>
          </div>
          <div class="box">
            <div class="course">
              <div class="cover">
                <img :src="info1.cover"/>
              </div>
              <div class="intro">
                <div class="name">{{info1.name}}</div>
                <!--<div class="author"><text class="name">{{item.teacherName}}<text class="tag"></text></text></div>-->
                <div class="count">
                  <text class="number">共{{info1.sections}}讲</text>
                </div>
                <div>
                  <del>￥{{info1.origPrice}}</del>
                  <text class="price">￥{{info1.marketPrice}}</text>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="column enhance">
          <div class="title">背词典
            <router-link :to="{path:'/courseDetail',query:{id:info2.courseShopId}}" replace class="more">
              <van-icon name="arrow" size="16px" color="#fff"/>
            </router-link>
          </div>
          <div class="box">
            <div class="course">
              <div class="cover">
                <img :src="info2.cover"/>
              </div>
              <div class="intro">
                <div class="name">{{info2.name}}</div>
                <!--<div class="author"><text class="name">{{item.teacherName}}<text class="tag"></text></text></div>-->
                <div class="count">
                  <text class="number">共{{info2.sections}}讲</text>

                </div>
                <div>
                  <del>￥{{info2.origPrice}}</del>
                  <text class="price">￥{{info2.marketPrice}}</text>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column classic">
          <div class="title">名著精读
            <router-link :to="{path:'/courseDetail',query:{id:info3.courseShopId}}" replace class="more">
              <van-icon name="arrow" size="16px" color="#fff"/>
            </router-link>
          </div>
          <div class="box">
            <div class="course">
              <div class="cover">
                <img :src="info3.cover"/>
              </div>
              <div class="intro">
                <div class="name">{{info3.name}}</div>
                <!--<div class="author"><text class="name">{{item.teacherName}}<text class="tag"></text></text></div>-->
                <div class="count">
                  <text class="number">共{{info3.sections}}讲</text>
                </div>
                <div>
                  <del>￥{{info3.origPrice}}</del>
                  <text class="price">￥{{info3.marketPrice}}</text>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column grade">
          <div class="title">专项提升课
            <router-link :to="{path:'/courseDetail',query:{id:info4.courseShopId}}" replace class="more">
              <van-icon name="arrow" size="16px" color="#fff"/>
            </router-link>
          </div>
          <div class="box">
            <div class="course">
              <div class="cover">
                <img :src="info4.cover"/>
              </div>
              <div class="intro">
                <div class="name">{{info4.name}}</div>
                <!--<div class="author"><text class="name">{{item.teacherName}}<text class="tag"></text></text></div>-->
                <div class="count">
                  <text class="number">共{{info4.sections}}讲</text>
                </div>
                <div>
                  <del>￥{{info4.origPrice}}</del>
                  <text class="price">￥{{info4.marketPrice}}</text>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--<div class="column enhance">-->
          <!--<div class="title">直播班-->
            <!--<router-link :to="{path:'/courseDetail',query:{id:info5.courseShopId}}" replace class="more">-->
              <!--<van-icon name="arrow" size="16px" color="#fff"/>-->
            <!--</router-link>-->
          <!--</div>-->
          <!--<div class="box">-->
            <!--<div class="course">-->
              <!--<div class="cover">-->
                <!--<img :src="info5.cover"/>-->
              <!--</div>-->
              <!--<div class="intro">-->
                <!--<div class="name">{{info5.name}}</div>-->
                <!--&lt;!&ndash;<div class="author"><text class="name">{{item.teacherName}}<text class="tag"></text></text></div>&ndash;&gt;-->
                <!--<div class="count">-->
                  <!--<text class="number">共{{info5.sections}}讲</text>-->
                <!--</div>-->
                <!--<div>-->
                  <!--<del>￥{{info5.origPrice}}</del>-->
                  <!--<text class="price">￥{{info5.marketPrice}}</text>-->
                <!--</div>-->
              <!--</div>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->

      </div>
    </div>
    <nav-bar></nav-bar>
  </div>
</template>

<script>
  import {reactive, onMounted, toRefs} from "vue";
  // import { useRouter } from "vue-router";
  import {showLoadingToast} from "vant";
  import {getLocal} from "@/utils/util";
  import {getCarousel} from "@/service/common";
  import {getCourseCategory, recomendList} from "@/service/course";
  import swiper from "@/components/Swiper.vue";
  import navBar from "@/components/NavBar";

  export default {
    name: "home",
    components: {
      swiper,
      navBar,
    },
    data() {
      return {
        categorys: [],//课程类别
        category1: {},
        category2: {},
        category3: {},
        category4: {},
        category5: {},
        info1: {},
        info2: {},
        info3: {},
        info4: {},
        info5: {}

      }

    },
    mounted: function () {
      getCourseCategory().then(res => {
        this.categorys = res.data.data;
        this.category1 = res.data.data[0];
        this.category2 = res.data.data[1];
        this.category3 = res.data.data[2];
        this.category4 = res.data.data[3];
        this.category5 = res.data.data[4];

      });

      recomendList().then(res => {
        this.info1 = res.data.data[0];
        this.info2 = res.data.data[1];
        this.info3 = res.data.data[2];
        this.info4 = res.data.data[3];
        this.info5 = res.data.data[4];
      })

    },

    setup() {
      // const router = useRouter()
      const state = reactive({
        swiperList: [], // 轮播图列表
        isLogin: false, //是否已登录

      });
      onMounted(async () => {
        const token = getLocal("token");
        if (token) {
          state.isLogin = true;
        }

        showLoadingToast({
          message: "加载中",
        });
        const {data} = await getCarousel();
        state.swiperList = data.data;
        // Toast.clear();
      });


      return {
        ...toRefs(state),
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import "../assets/styles/mixin";

  .content {
    padding-bottom: 90px;
  }

  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }

  .category-list {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    // width: 100%;
    padding: 0 16px 8px 16px;
    background-color: #fff;
    margin: 6px auto;
    a {
      display: flex;
      flex-direction: column;
      width: 20%;
      text-align: center;
      img {
        @include wh(80px, 80px);
        margin: 13px auto 8px auto;
      }
      span {
        font-size: 20px;
      }
    }
  }

  .colgroup {
    padding: 16px 16px;
    background-color: #fff;
    .column {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      margin-bottom: 15px;
      .title {
        font-size: 36px;
        font-weight: 600;
        color: #FFF;
        line-height: 104px;
        padding-left: 15px;
        padding-right: 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .more {
          padding-left: 25px;
        }
      }
      .box {
        padding-bottom: 15px;
        > .cover {
          height: 230px;
          margin: 15px 15px 0px 15px;
          img {
            height: 100%;
            width: 100%;
          }
        }
        .course {
          display: flex;
          justify-content: space-between;
          margin: 15px 15px 0 15px;
          .cover {
            height: 230px;
            width: 185px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .intro {
            flex: 1;
            padding-left: 25px;
            line-height: 60px;
            font-size: 28px;
            > .name {
              font-size: 32px;
              font-weight: 600;
            }
            .author {
              .tag {
                color: #707070;
              }
            }
            .count {
              display: flex;
              justify-content: space-between;
              .number {
                color: #707070;
              }

            }
            .price {
              color: rgb(242, 85, 0);
              font-size: 32px;
              font-weight: 500;
              margin-left: 30px;
            }
          }
        }
      }
    }
  }

  .member {
    background-color: #FFE4E1;
    .title {
      background-color: #FA8072;
    }
  }

  .grade {
    background-color: #F0FFF0;
    .title {
      background-color: #3CB371;
    }
  }

  .enhance {
    background-color: #FFF0F5;
    .title {
      background-color: #d4237a;
    }
  }

  .classic {
    background-color: #E1FFFF;
    .title {
      background-color: #4682B4;
    }
  }
</style>
